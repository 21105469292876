import {
  getCurrentInstance,
  ref, watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableLocationModule } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import moment from 'moment';

export default function useReportNewDebt() {
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const rows = ref([]);

  // Table Handlers
  const columns = [
    {
      label: t('Tháng'),
      field: 'month',
    },

    {
      label: t('Mã đặt cọc'),
      field: 'reservationCode',
    },
    {
      label: t('Mã hợp đồng'),
      field: 'contractCode',
    },
    {
      label: t('Tòa nhà'),
      field: 'apartmentName',
    },
    {
      label: t('Căn hộ'),
      field: 'roomName',
    },
    {
      label: t('Giá cho thuê'),
      field: 'price',
    },
    {
      label: t('Cọc theo hợp đồng'),
      field: 'deposit',
    },
    {
      label: t('Tiền nhà ngày lẻ'),
      field: 'leasePriceOddDays',
    },
    {
      label: t('Tiền nhà tháng mới'),
      field: 'leasePricePeriod',
    },
    {
      label: t('Số ngày lẻ'),
      field: 'numberOddDays',
    },
    {
      label: t('Số tháng KM'),
      field: 'promotionMonth',
      type: 'number',
    },
    {
      label: t('Số tiền KM'),
      field: 'promotionPricePerMonth',
      type: 'number',
    },
    {
      label: t('Tiền phải TT trừ KM'),
      field: 'total',
    },
    {
      label: t('Đã TT'),
      field: 'paid',
    },
    {
      label: t('Còn nợ'),
      field: 'remain',
    },
    {
      label: t('Ngày cọc'),
      field: 'depositDate',
    },
    {
      label: t('Số tháng cọc'),
      field: 'depositMonth',
    },
    {
      label: t('Ngày tính tiền thuê'),
      field: 'billingDate',
    },
    {
      label: t('Số tháng HĐ'),
      field: 'contractMonth',
    },
    {
      label: t('Người cho thuê'),
      field: 'creator',
    },
    {
      label: t('Người giới thiệu'),
      field: 'referral',
    },
    {
      label: t('Tên KH'),
      field: 'tenant',
    },

  ];

  // filter
  const apartment = ref(null);
  const location = ref(null);
  const month = ref(moment().format("MM/YYYY"));
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { month: moment().format("MM/YYYY") },
    sort: {},
    page: 1,
    perPage: 5000,
  });
  const vm = getCurrentInstance().proxy;

  //   API Call
  const fetchRooms = () => {
    isLoading.value = true;
    store
      .dispatch('report/getReportNewDebt', serverParams.value)
      .then(response => {
        totalRecords.value = response.data.length;
        rows.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const fetchData = () => {
    fetchRooms();
  };

  const exportData = () => {
    store
      .dispatch('report/exportReportNewDebt', serverParams.value)
      .then(response => {
        vm.$router.push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onViewDetailItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const contractMonth = contract => {
    if (moment(contract.startDate).isValid() && moment(contract.endDate).isValid()) {
      return Math.abs(moment(contract.startDate).diff(moment(contract.endDate), 'months')) + 1;
    }
    return 0;
  };

  const contractType = contract => {
    if (contract.type === 'extend') {
      return "Hợp đồng gia hạn";
    }
    if (contract.type === 'move') {
      return "Hợp đồng chuyển";
    }
    if (contract.type === 'transfer') {
      return "Hợp đồng nhượng";
    }
    return "";
  };

  //   Watch
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });

  return {
    item,
    columns,
    rows,
    apartment,
    month,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,
    location,
    isEnableLocationModule,
    fetchData,
    fetchRooms,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    exportData,
    t,
    contractMonth,
    contractType,
  };
}
